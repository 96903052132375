<template lang="pug">
tr.overdraft-schedule-invoice-list-item
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paymentDate }) }}
  td.text-nowrap
      fi-datepicker(
        v-if      = 'editing'
        :min-date = 'invoice.paymentDate'
        v-model   = 'newDeadlineDate'
      )
      template(v-else) {{ $t('common:formatDate', { value: invoice.deadlineDate }) }}
  td.money {{ invoice.balance | money }}
  td.animated.fadeIn.money {{ invoice.principal | money }}
  td.money {{ invoice.interest | money }}
  td.money.has-popover(:id = 'invoiceUid') {{ invoice.fee | money }}
  td.money {{ sum | money }}
  td.money {{ paidSum | money }}
  td.money {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(invoice.status)}`') {{ status.human }}
  td(v-if="flexible")
    .animated.fadeIn(v-if="editable")
      .btn-group.btn-group.btn-group-sm
        button.btn.btn-success.btn-sm(
          v-if = 'editing'
          @click = 'changeDeadlineDate'
        )
          i.fa.fa-spinner.fa-pulse.fa-lg(v-if = '$vueLoading.isLoading("overdraft:schedule:deadline:save")')
          i.fas.fa-check.fa-fw(v-else)

        button.btn-sm.btn.btn-outline-primary.animated.fadeIn(
          v-else-if = 'editable'
          @click = 'editing = true'
        )
          i.far.fa-edit.fa-lg.fa-fw
  b-popover(
    :target   = 'invoiceUid'
    triggers  = 'hover'
    placement = 'bottom'
  )
    tr(v-for = '(value, key) in invoice.feeDetails')
      .row.text-nowrap.popover-container(v-for = '(value, key) in value')
        .col-6 {{ key }}
        .col-6.text-position-end {{ value }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import { mapGetters } from 'vuex'
import FiDatepicker from '@/components/FiDatepicker'

export default {
  name: 'overdraft-schedule-invoice-list-item',

  components: { FiDatepicker, FiFormField },

  props: {
    invoice: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    editable: Boolean,
    flexible: Boolean
  },

  data: (vm) => ({
    editing: false,
    newDeadlineDate: vm.invoice.deadlineDate
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    invoiceUid () {
      return `invoice_${this._uid}`
    },
    sum () {
      const { principal, interest, fee } = this.invoice
      return principal + interest + fee
    },
    paidSum () {
      const { amountPaid } = this.invoice
      return amountPaid
    },
    status () {
      return this.classifierById(this.invoice.status.classifier, this.invoice.status.id)
    }
  },
  methods: {
    changeDeadlineDate () {
      this.$emit('input', {
        paymentDate: this.invoice.deadlineDate,
        newPaymentDate: this.newDeadlineDate,
        index: this.index,
        done: () => {
          this.editing = false
        }
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.has-popover {
  cursor: pointer;
}
</style>
